import React, { useState } from 'react';
import '../styles/Home.css';
import { Link } from 'react-router-dom';
import Lottie from 'lottie-react';
const faqs = [
  {
    question: 'Mennyi ideig tart egy kijelzőcsere?',
    answer: 'A kijelzőcsere általában 1-2 órát vesz igénybe, de ez függ a készülék típusától és az aktuális munkamennyiségtől.'
  },
  {
    question: 'Mennyibe kerül az akkumulátorcsere?',
    answer: 'Az akkumulátorcsere ára készüléktől függően változik. Kérjük, érdeklődjön konkrét árajánlatért.'
  },
  {
    question: 'Kell időpontot foglalnom?',
    answer: 'Nem szükséges időpontot foglalni, de ajánlott, hogy elkerülje a várakozást.'
  },
  {
    question: 'Milyen garanciát vállalnak a javításokra?',
    answer: 'Minden javításra 6 hónap garanciát vállalunk, amely a cserélt alkatrészekre és az elvégzett munkára vonatkozik.'
  },
  {
    question: 'Mennyi idő alatt készül el az ultrahangos tisztítás?',
    answer: 'Az ultrahangos tisztítás általában 30-60 percet vesz igénybe, a készülék szennyezettségétől függően.'
  },
  {
    question: 'Milyen típusú készülékeket javítanak?',
    answer: 'Szervizünk szinte minden márka készülékeit javítja, beleértve az Apple, Samsung, Huawei, és más népszerű gyártókat is.'
  },
  {
    question: 'Adatvesztés előfordulhat a javítás során?',
    answer: 'Ritkán fordul elő adatvesztés, de javasoljuk, hogy a javítás előtt készítsen biztonsági másolatot adatainak védelme érdekében.'
  },
];
const services = [
  {
    title: "iPhone szerviz",
    description: "Hozza el iPhone-ját meghibásodás esetén szakértőinkhez, akik gyors és megbízható javítást végeznek akkumulátor cserében, kijelző javításban vagy szoftveres hibák orvoslásában. Az Ön iPhone-ja nálunk a legjobb kezekben van!",
    image: "https://szerviz.slimstore.hu/assets/service_image1.png",
    link: "#",
  },
  {
    title: "iPad szerviz",
    description: "Meghibásodott iPadjét hozza el professzionális szervizünkbe, ahol gyorsan és precízen helyreállítjuk akkumulátor cserével, kijelző javítással vagy szoftveres hibák javításával. Az Ön iPadje nálunk a legjobb kezekben van!",
    image: "https://szerviz.slimstore.hu/assets/ipadservice.png",
    link: "#",
  },
  {
    title: "Apple Watch szerviz",
    description: "Az Apple Watch-ának bármilyen hibája esetén hozza el hozzánk, ahol tapasztalt szakembereink a legújabb technológiával végzik a javítást akkumulátor cserében, kijelző javításban vagy szoftveres hibák orvoslásában. Az Ön órája nálunk a legjobb kezekben van!",
    image: "https://szerviz.slimstore.hu/assets/applewatchservice.png",
    link: "#",
  },
  
  {
    title: "Mac szerviz",
    description: "Bármilyen Mac számítógépről legyen is szó, minden hibát megoldunk. Hozza el hozzánk a problémás eszközét, és mi gyorsan, precízen és szakszerűen visszaállítjuk a tökéletes működést!",
    image: "https://szerviz.slimstore.hu/assets/macservice.png",
    link: "#",
  },
];
const ServiceCard = ({ title, description, image, link }: any) => (
  <div style={{margin: '20px', textAlign: 'center', width: '250px' }}>
    <img src={image} alt={title} style={{ width: '100%', height: 'auto', borderRadius: '20px' }} />
    <h3>{title}</h3>
    <p>{description}</p>
    <Link to="/szerviz"><button className='priceButton'>Árak megtekintése</button></Link>
  </div>
);
const Home: React.FC = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const handleToggle = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  return (
    <div className="home">
      <section className="intro">
        <h1>Slim Service</h1>
        <p>A Slim Service gyors és megbízható okostelefon szerviz, amely professzionális javítást kínál minden típusú készülékhez.</p>
      </section>
      <div className="containerwhole">
    <div className="container">
      
        <div className="item">
            <img src="https://szerviz.slimstore.hu/assets/screenreplace.png" style={{width: "45px"}} alt="Kijelző csere"></img>
            <p>Kijelzőcsere 1 órán belül elkészül</p>
        </div>
        <div className="item">
            <img src="https://szerviz.slimstore.hu/assets/batteryreplace.png" style={{width: "45px"}} alt="Akkumulátor csere"></img>
            <p>Akkumulátor cserék akár 30 percen belül</p>
        </div>
        <div className="item">
            <img src="https://szerviz.slimstore.hu/assets/softwareupdate.png" style={{width: "45px"}} alt="Szoftverfrissítés"></img>
            <p>Szoftverfrissítések, adatmentések helyben</p>
        </div>
        </div>
        
    </div>
    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', padding: '20px' }}>
    {services.map((service, index) => (
      <ServiceCard key={index} {...service} />
    ))}
  </div>
  <div style={{ margin: "auto", textAlign: "center", background: "black", color: "white"}}>
  <Lottie style={{height:"100px"}} animationData={require('../assets/question.json')} />
    <h2>Gyakran Ismételt Kérdések</h2>
  <div className="faq-container">
    
  {faqs.map((faq, index) => (
          <div key={index} className="faq-item">
            <div className="faq-question" onClick={() => handleToggle(index)}>
              {faq.question}
            </div>
            {openIndex === index && (
              <div className="faq-answer">
                {faq.answer}
              </div>
            )}
          </div>
        ))}
</div>
</div>
    </div>
  );
}

export default Home;
